import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { VariantType, SnackbarKey } from 'notistack';

interface NotifierState {
  notifications: Notification[],
}

export interface Notification {
  message: string, 
  key?: SnackbarKey,
  variant?: VariantType,
  preventDuplicate?: boolean;
  dismissed?: boolean,
}

const initialState: NotifierState = {
  notifications: [], 
};

export const notifierSlice = createSlice({
  name: 'notifier',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<{notification: Notification}>) => {
      state.notifications.push(action.payload.notification);
    },
    closeNotification: (state, action: PayloadAction<{key: SnackbarKey, dismissAll: boolean}>) => {
      state.notifications.map(notification => (
        (action.payload.dismissAll || notification.key === action.payload.key)
          ? notification.dismissed = true
          : notification
      ));
    },
    removeNotification: (state, action: PayloadAction<{key: SnackbarKey}>) => {
      state.notifications = state.notifications.filter(notification => notification.key !== action.payload.key);
    }
  },
});

export const { addNotification, closeNotification, removeNotification } = notifierSlice.actions;

export default notifierSlice.reducer;

export const enqueueSnackbar = (notification: Notification) => {
  return async (dispatch: Dispatch<any>) => {
    const key = notification?.key ?? new Date().getTime() + Math.random();
    notification.key = key;
    dispatch(addNotification({notification: notification}));
  }
}

export const closeSnackbar = (key: SnackbarKey) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(closeNotification({key: key, dismissAll: !key}));
  }
}

export const removeSnackbar = (key: SnackbarKey) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(removeNotification({key: key}));
  }
}