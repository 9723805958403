import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CastingState {
  casting: boolean | undefined,
};

const initialState: CastingState = { 
  casting: false,
};

export const castingSlice = createSlice({
  name: 'casting',
  initialState,
  reducers: {
    setCasting: (state, action: PayloadAction<boolean>) => {
      state.casting = action.payload;
    },
  },
});

export const { setCasting } = castingSlice.actions;

export default castingSlice.reducer;