import React from 'react';
import Notifier from '../features/notifier/Notifier';
import { SnackbarProvider } from 'notistack';
import { createMuiTheme, CssBaseline, ThemeOptions, ThemeProvider, useMediaQuery } from '@material-ui/core';
import Router from './Router';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../app/store';

function Theme() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const { themeMode } = useSelector(
    (state: RootState) => ({
      themeMode: state.user.themeMode
    }),
    shallowEqual
  );

  const theme = React.useMemo(
    () => {
      const light: ThemeOptions = {
        palette: {
          type: 'light',
          primary: {
            main: '#497E82',
          },
          secondary: {
            main: '#F16D58',
          },
        },
      }
      
      const dark: ThemeOptions = {
        palette: {
          type: 'dark',
          primary: {
            main: '#497E82',
          },
          secondary: {
            main: '#F16D58',
          },
        },
      }

      let themeOptions: ThemeOptions;
      if (themeMode) {
        if (themeMode === 'light') {
          themeOptions = light;
        } else if (themeMode === 'dark') {
          themeOptions = dark;
        } else if (themeMode === 'system') {
          themeOptions = prefersDarkMode ? dark : light;
        } else {
          themeOptions = dark;
        }
      } else {
        themeOptions = prefersDarkMode ? dark : light;
      }

      return createMuiTheme(themeOptions);
    },
    [prefersDarkMode, themeMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Notifier />
        <Router />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default Theme;
