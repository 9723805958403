import firebaseApp from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics'
import { Env, getEnv } from './utils';

export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: 'Email already in use',
  EMAIL_INVALID: 'Email is invalid',
  EMAIL_NOT_FOUND: 'Email was not found',
  PASSWORD_INVALID: 'Invalid credentials',
  USER_DISABLED: 'User disabled',
  TOO_MANY_REQUESTS: 'Too many attempts made, try again later',
  EXPIRED_ACTION_CODE: 'The invitation link has expired, get in touch with your administrator',
  INVALID_ACTION_CODE: 'The invitation link has expired, get in touch with your administrator',
  QUOTA_EXCEEDED_STORAGE: 'Internal server error, get in touch with your administrator',
  UNAUTHENTICATED_STORAGE: 'Unauthenticated, please authenticate and try again',
  UNAUTHORIZED_STORAGE: 'Unauthorized, you are not authorized to perform this action',
};

const casting: boolean = cast.framework.CastReceiverContext.getInstance().getDeviceCapabilities() != null;
let firebaseConfig;
if (getEnv(casting) === Env.production) {
  firebaseConfig = {
    apiKey: "AIzaSyCBgcQ-rTj1VBCXqImC_x2BQ3dqZZZrEzk",
    authDomain: "tallyr-bc7c2.firebaseapp.com",
    databaseURL: "https://tallyr-bc7c2-default-rtdb.firebaseio.com",
    projectId: "tallyr-bc7c2",
    storageBucket: "tallyr-bc7c2.appspot.com",
    messagingSenderId: "521260149069",
    appId: "1:521260149069:web:72db5234b6b2cd90746f04"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyDh1okex9lC9s-EjeKal9KFHVojRZlyAu0",
    authDomain: "tallyr-debug.firebaseapp.com",
    projectId: "tallyr-debug",
    storageBucket: "tallyr-debug.appspot.com",
    messagingSenderId: "936222978925",
    appId: "1:936222978925:web:73437eae9dab908f092064"
  };
}


firebaseApp.initializeApp(firebaseConfig);
//firebaseApp.analytics();

export default firebaseApp;