import { createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { AppThunk } from '../../app/store';
import firebaseApp from '../../firebase';
import { Player } from '../../models/player';
import { orderBy } from 'natural-orderby';

interface GameState {
  id: string | undefined,
  userId: string | undefined,
  title: string | undefined,
  pointScale: number | undefined,
  players: Player[],
  sortColumn: number,
  sortAscending: boolean,
  selectedRoundNum: number,
  visibleRoundNum: number,
  joinCode: string | undefined,
  showJoinCode: boolean,
  numRounds: number,
};

const initialState: GameState = { 
  id: undefined,
  userId: undefined,
  title: undefined,
  pointScale: undefined,
  players: [],
  sortColumn: -1,
  sortAscending: false,
  selectedRoundNum: 1,
  visibleRoundNum: 1,
  joinCode: undefined,
  showJoinCode: true,
  numRounds: 1,
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    gameInit: (state, action: PayloadAction<{gameId: string}>) => {
      state.id = action.payload.gameId;
    },
    gameUpdate: (state, action: PayloadAction<{game: GameState}>) => {
      return action.payload.game;
    },
  },
});

const gameState = (gameState: GameState) => gameState;

export const nonDeletedPlayersSelector = createDraftSafeSelector(
  gameState,
  // Sort players by name by default
  (state) => orderBy(
    state.players.filter((player: Player) => !player.isDeleted),
    [player => player.name],
    ['asc']
  )
);

export const { gameInit, gameUpdate } = gameSlice.actions;

export default gameSlice.reducer;

let unsubscribe: any;

export const startGameStream = (gameId: string): AppThunk => {
  return (dispatch: Dispatch<any>) => {
    if (gameId) {
      if (unsubscribe) {
        unsubscribe();
        console.info("Detached game stream");
      }
      unsubscribe = firebaseApp.firestore().collection('games').doc(gameId).onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const players:Player[] = [];
          const snapshotPlayers:{[key: string]: any} = snapshot.get("players");
          if (snapshotPlayers) {
            for (const userId in snapshotPlayers) {
              players.push({
                id: userId,
                name: snapshotPlayers[userId]["name"],
                color: snapshotPlayers[userId]["color"],
                isDeleted: snapshotPlayers[userId]["isDeleted"] ?? false,
              })
            }          
          }
          
          dispatch(gameUpdate({
            game: {
              id: snapshot.id,
              title: snapshot.get("title"),
              userId: snapshot.get("userId"),
              pointScale: snapshot.get("pointScale"),
              players: players,
              sortColumn: snapshot.get("sortColumn"),
              sortAscending: snapshot.get("sortAscending"),
              selectedRoundNum: snapshot.get("selectedRoundNum"),
              visibleRoundNum: snapshot.get("visibleRoundNum"),
              joinCode: snapshot.get("joinCode"),
              showJoinCode: snapshot.get("showJoinCodeCast"),
              numRounds: snapshot.get('numRounds'),
            }
          }))
        }
      }, (error) => {
        console.error(error.message)
      });
      console.info("Attached game stream for game id: "+gameId);
    } else {
      console.info("Not attaching game stream as game id is null or empty");
    }
  }
}