import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarKey, useSnackbar } from 'notistack';
import { RootState } from '../../app/store';
import { removeSnackbar, Notification } from './notifierSlice';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

let displayed: SnackbarKey[] = [];

// https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example?file=/App.js
const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state: RootState) => state.notifier.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (key: SnackbarKey) => {
    displayed = [...displayed, key];
  };

  const removeDisplayed = (key: SnackbarKey) => {
    displayed = [...displayed.filter(checkKey => key !== checkKey)];
  };

  useEffect(() => {
    notifications.forEach((notification: Notification) => {
      if (notification.dismissed ?? false) {
          // dismiss snackbar using notistack
          closeSnackbar(notification.key);
          return;
      }

      // do nothing if snackbar is already displayed
      if (notification.key ? displayed.includes(notification.key) : false) return;

      // display snackbar using notistack
      enqueueSnackbar(notification.message, {
        key: notification.key,
        variant: notification.variant,
        preventDuplicate: notification.preventDuplicate,
        action: key => (
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
        /*
        onClose: (event, reason, myKey) => {
            if (options.onClose) {
                options.onClose(event, reason, myKey);
            }
        },
        */
        onExited: (event, key: SnackbarKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(key));
            removeDisplayed(key);
        },
      });

      // keep track of snackbars that we've displayed
      if (notification.key) storeDisplayed(notification.key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
