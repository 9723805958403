import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SignIn from '../features/auth/SignIn';
import { Game } from '../features/game/Game';
import { NotFound } from '../features/not-found/NotFound';
import PrivateRoute from './PrivateRoute';

// The Switch tag below evaluates the routes in the order they are listed. Whichever one matches first is the
// one that will get rendered. The exact property makes it so the exact location of the browser has to match 
// the path that is being passed in to the path property.
function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.SIGNIN} component={SignIn} />
        <PrivateRoute exact path={paths.ROOT} component={Game} />
        <PrivateRoute exact path={paths.ROOT_PARAM} component={Game} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export const paths = {
  SIGNIN: "/signin",
  ROOT: "/",
  ROOT_PARAM: "/:paramGameId?",
}

export default Router;
