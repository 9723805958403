import React from 'react';
import ReactDOM from 'react-dom';
import { persistor, store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import Theme from './components/Theme';
import { castSignIn, signOut, startAuthStream } from './features/auth/authSlice';
import { setCasting } from './features/casting/castingSlice';
import { CastReceiverContext } from 'chromecast-caf-receiver/cast.framework';

export let castReceiverContext: CastReceiverContext;

function App() {
  // const castDebugLogger = cast.debug.CastDebugLogger.getInstance();
  // castDebugLogger.setEnabled(true);
  // castDebugLogger.showDebugLogs(true);

  castReceiverContext = cast.framework.CastReceiverContext.getInstance();
  // If the cast receiver doesn't have any capabilities then we assume this app is not running on a cast device
  const casting: boolean = castReceiverContext.getDeviceCapabilities() != null;
  store.dispatch(setCasting(casting));
  store.dispatch(startAuthStream());

  if (casting) {
    const castReceiverContext = cast.framework.CastReceiverContext.getInstance();
    castReceiverContext.addEventListener(cast.framework.system.EventType.SENDER_DISCONNECTED, (event) => {
      // castDebugLogger.debug('event: '+event.data);
      store.dispatch(signOut());
    });
  
    castReceiverContext.addCustomMessageListener("urn:x-cast:app.tallyr", (event) => {
      // castDebugLogger.debug("event.data.type: "+event.data.type);
      // castDebugLogger.debug("event.data.token: "+event.data.token);
      // castDebugLogger.debug("event.data.gameId: "+event.data.gameId);
      if (event.data.token && event.data.gameId) {
        store.dispatch(castSignIn(event.data.token, event.data.gameId));
      } else {
        // castDebugLogger.debug("token or gameId is null or empty");
        castReceiverContext.sendCustomMessage("urn:x-cast:app.tallyr", undefined, {
          'type': 'auth',
          'level': 'error',
          'message': 'Invalid parameters provided',
        });
      }
    });
    castReceiverContext.start();
  }
  
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Theme />
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(App(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
