import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import firebaseApp from '../../firebase';

interface UserState {
  themeMode: string | undefined,
};

const initialState: UserState = { 
  themeMode: 'dark',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userUpdate: (state, action: PayloadAction<{user: UserState}>) => {
      return action.payload.user;
    },
  },
});

export const { userUpdate } = userSlice.actions;

export default userSlice.reducer;

export const startUserStream = (userId: string): AppThunk => {
  return (dispatch: Dispatch<any>) => {
    let unsubscribe: any;
    if (userId) {
      if (unsubscribe) {
        unsubscribe();
        console.info("Detached user stream");
      }
      unsubscribe = firebaseApp.firestore().collection('users').doc(userId).onSnapshot((snapshot) => {
        if (snapshot.exists) {     
          dispatch(userUpdate({
            user: {
              themeMode: snapshot.get("settings.themeMode")
            }
          }));
        }
      }, (error) => {
        console.error(error.message);
      });
      console.info("Attached user stream for user id: "+userId);
    } else {
      console.info("Not attaching user stream as user id is null or empty");
    }
  }
}