import React from 'react';
import {
  createStyles,
  Fab,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { RootState } from '../app/store';
import { AuthStatus, signOut } from '../features/auth/authSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { paths } from './Router';
import { Redirect } from 'react-router-dom';
import { ExitToApp } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: '100vh',
      minHeight: '100%',
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.background.default,
    },
    hide: {
      display: 'none',
    },
    container: {
      flexGrow: 1,
      height: '100vh',
      minHeight: '100%',
      width: '100%'
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
      minHeight: '100%',
      height: '100%',
      //flex: '1 1 auto',
      //display: 'flex'
    },
    title: {
      flexGrow: 1,
    },
    drawerItemIcon: {
      paddingLeft: 8,
    },
    select: {
      minWidth: '100px',
      background: 'white',
      color: theme.palette.grey[700],
      borderColor: theme.palette.grey[300],
      borderStyle:'solid',
      borderWidth: '2px',
      borderRadius: '4px',
      paddingLeft: '8px',
      paddingTop: '8px',
      paddingBottom: '8px',
      "&:hover":{
        borderColor: theme.palette.grey[400],
      },
      "&:focus":{
        borderRadius: '4px',
        background: 'white',
        borderColor: theme.palette.secondary.main[200]
      },
    },
    icon:{
      color: theme.palette.grey[500],
      right: 12,
      position: 'absolute',
      userSelect: 'none',
      pointerEvents: 'none'
    },
    list: {
      paddingTop:0,
      paddingBottom:0,
      background:'white',
      "& li.Mui-selected":{
        fontWeight:700
      }
    },
    fab: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      position: 'fixed'
    }
  }),
);

type LayoutProps = {
  children?: React.ReactNode;
};

export function Layout(props: LayoutProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isAuthed, casting } = useSelector(
    (state: RootState) => ({
      isAuthed: state.auth.status === AuthStatus.success,
      casting: state.casting.casting
    }),
    shallowEqual
  );

  return (
    <div className={classes.root}>
      {!isAuthed && <Redirect to={paths.SIGNIN} />}
      <div className={classes.container}>
        <main className={classes.content}>
          {props.children}
        </main>
      </div>
      {!casting && <Fab aria-label="sign out" size="small" className={classes.fab} onClick={() => dispatch(signOut())}>
        <ExitToApp />
      </Fab>}
    </div>
  );
}
