import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { TableContainer, createStyles, makeStyles, TableBody, Table, TableHead, Theme, TableRow, TableCell, Paper, Typography, CircularProgress, Box, Badge, Grid, Avatar, withStyles, useTheme, Container } from '@material-ui/core';
import React, { RefObject, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../app/store';
import { Player } from '../../models/player';
import { gameInit, nonDeletedPlayersSelector, startGameStream } from './gameSlice';
import FunctionsIcon from '@material-ui/icons/Functions';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import useDynamicRefs from 'use-dynamic-refs';
import styles from './Game.module.css';
import { orderBy } from 'natural-orderby';
import { Env, getEnv, parseColor } from '../../utils';
import { startUserStream } from '../user/userSlice';
import logo from '../../logo64.png';
import name from '../../name26.png';
import { startPlayerStreams } from '../scores/scoresSlice';
import logoName from '../../logo-name256.png';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface ParamTypes {
  paramGameId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    cell: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    playerColorIcon: {
      marginRight: theme.spacing(1),
    },
    sticky: {
      position: "sticky",
      left: 0,
      background: theme.palette.background.paper,
      zIndex: 2,
    },
    scoreCell: {
      minWidth: "80px",
      maxWidth: "80px",
      textAlign: "center",
      verticalAlign: "middle"
    }
  }),
);

export function Game() {
  const { paramGameId } = useParams<ParamTypes>();
  const dispatch = useDispatch();
  const [getRef, setRef] = useDynamicRefs();
  const tableRef = useRef<Element>();
  const fixedColumnRef = useRef<Element>();

  const classes = useStyles();

  const { id, casting, title, players, sortColumn, sortAscending, visibleRoundNum, userId, joinCode, showJoinCode, numRounds, scores } = useSelector(
    (state: RootState) => ({
      id: state.game.id,
      casting: state.casting.casting,
      title: state.game.title,
      players: nonDeletedPlayersSelector(state.game),
      sortColumn: state.game.sortColumn,
      sortAscending: state.game.sortAscending,
      visibleRoundNum: state.game.visibleRoundNum,
      userId: state.auth.userId,
      joinCode: state.game.joinCode,
      showJoinCode: state.game.showJoinCode,
      numRounds: state.game.numRounds,
      scores: state.scores,
    }),
    shallowEqual
  );

  let gameId: string;
  if (paramGameId) {
    // For a better dev experience, allow passing in a gameId from the URL params so the UI can be deved and tested
    // with just a normal browser instead of having to deploy to a chromecast each time.
    gameId = paramGameId;
    dispatch(gameInit({ gameId: gameId }));
  } else {
    gameId = id;
  }

  // Calculate totals for each player
  const totals: Record<string, number> = {};
  players.forEach((player: Player) => {
    Array.from({length: numRounds}, (value, key) => key+1).forEach((roundNum: number) => {
      const existingTotal: number = totals[player.id] ?? 0;
      const playerScores: Record<number, number> = scores[player.id] ?? {};
      const roundScore: number = playerScores[roundNum] ?? 0;
      totals[player.id] = existingTotal + roundScore;
    });
  });

  // Sort players by provided sortColumn
  let sortedPlayers: Player[] = [];
  if (sortColumn === 0) {
    sortedPlayers = orderBy(
      players,
      [player => totals[player.id], player => player.name],
      [sortAscending ? 'asc' : 'desc', sortAscending ? 'asc' : 'desc']
    );
  } else {
    let playerIds: string[] = [];
    for (const playerId in scores) {
      playerIds.push(playerId);
    }
    const sortedPlayerIds: string[] = orderBy(
      playerIds,
      [playerId => scores[playerId][sortColumn], playerId => players.find((player: Player) => player.id === playerId)?.name],
      [sortAscending ? 'asc' : 'desc', sortAscending ? 'asc' : 'desc']
    );
    sortedPlayerIds.forEach((playerId: string) => {
      const player: Player | undefined = players.find((player: Player) => player.id === playerId);
      if (player) {
        sortedPlayers.push(player);
      }
    });
  }

  useEffect(() => {
    dispatch(startUserStream(userId));
    dispatch(startGameStream(gameId));
  }, [dispatch, gameId, userId]);
    
  useDeepCompareEffect(() => {
    dispatch(startPlayerStreams(gameId, players.map((player: Player) => player.id)));
  }, [dispatch, gameId, players]);

  useEffect(() => {
    const roundRef: RefObject<Element> = getRef("round"+visibleRoundNum.toString()) as RefObject<Element>;
    if (fixedColumnRef && fixedColumnRef.current) {
      const fixedColumnPosition: number | undefined = fixedColumnRef.current?.getBoundingClientRect().right;
      if (roundRef && roundRef.current && fixedColumnPosition) {
        const roundPosition: number | undefined = roundRef.current?.getBoundingClientRect().left
        if (roundPosition) {
          const offset: number =  roundPosition - fixedColumnPosition;
          tableRef.current?.scrollTo({
            left: offset,
            behavior: "smooth"
          });
        }
      }
    }
   
  }, [getRef, visibleRoundNum])

  const SmallAvatar = withStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 25,
        height: 25,
        backgroundColor: theme.palette.type === "dark" ? "white" : "black"
      },
    }),
  )(Avatar);

  interface SortIconProps  { 
    column: number
    children: React.ReactNode
  }

  const SortIcon = (props: SortIconProps) => {
    const theme = useTheme();
    const color: string = theme.palette.type === "dark" ? "black" : "white";
    return <Badge
      invisible={props.column !== sortColumn}
      badgeContent={<SmallAvatar>{sortAscending ? <ArrowUpwardIcon style={{ color: color, fontSize: "medium" }} /> : <ArrowDownwardIcon style={{ color: color, fontSize: "medium" }} />}</SmallAvatar>}
    >
      {props.children}
    </Badge>
  }

  let result;
  if (casting && !gameId) {
    result = centerLoading();
  } else if (!casting && !gameId) {
    result = <Box
      display="flex" 
      alignItems="center"
      justifyContent="center"
    >
      <Box margin="auto">
        <Typography component="h1" variant="h5" align="center">App is running in <Box fontWeight='fontWeightBold' display='inline'>{Env[getEnv(casting)]}</Box> mode.</Typography>
        <Typography component="h1" variant="h5" align="center">Please provide a game id as the first param in the URL path.</Typography>
        <Typography component="h1" variant="h5" align="center">Example: localhost:3000/7414effb-2414-47e6-90b8-38e76efae924</Typography>
      </Box>
    </Box>
  } else {
    // I made the decision to not make the casting app backwards compatible when I migrated to the new backend scores data structure. Because of
    // this, we check to see if the currently casting game has a numRounds field. If it does, that means it has been migrated to the new backend
    // scores data structure. If it does not, that means it is still on the old structure. If the user updates their app, on the next launch of the
    // app it will migrate them to the latest structure and they will now have a numRounds field.
    if (!numRounds) {
      result = <Container component="main" maxWidth="md">
        <Grid container
          spacing={3}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item>
            <img src={logoName} alt="Logo" />
          </Grid>
          <Grid item><Typography component="h1" variant="h5" align="center">It seems your Tallyr app is not up-to-date. To be able to use the casting feature, you must update your Tallyr app to the latest version in the app store.</Typography>
          </Grid>
        </Grid>
      </Container>
    } else {
      result = <div style={styles}>
        {title
          ? <div>
            <Grid container direction="row" alignItems="center" justify="space-between" spacing={2} style={{ marginBottom: "8px" }}>
              <Grid item><Typography variant="h3" component="h1" align="center" gutterBottom={false}>{title}</Typography></Grid>
              <Grid item>
                <Box minHeight={72} alignItems="center" justifyContent="center" display="flex">
                  <Grid container direction="row" alignItems="center" spacing={2}>
                    {joinCode && showJoinCode &&
                    <Grid item>
                      <Typography variant="h6" component="h1" align="center" gutterBottom={false}>Join Code</Typography>
                      <Typography variant="h5" component="h1" align="center" gutterBottom={false}>{joinCode}</Typography>
                    </Grid>
                    }
                    <Grid item>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <img src={name} alt="Name" style={{ marginRight: "16px" }} />
                        <img src={logo} alt="Logo" />
                      </Box>  
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
              {players.length > 0
                ? <TableContainer component={Paper} ref={tableRef}>
                    <Table size="medium">
                      <TableHead>
                        <TableRow key="header">
                          <TableCell key="total" className={classes.sticky} ref={fixedColumnRef}>
                            <Grid container wrap="nowrap">
                              <Grid item>
                                <Box width="200px"></Box>
                              </Grid>
                              <Grid item>
                                <SortIcon column={0}><FunctionsIcon style={{ fontSize: "3rem", minWidth: "50px" }} /></SortIcon>
                              </Grid>
                            </Grid>
                          </TableCell>
                          {Array.from({length: numRounds}, (value, key) => key+1).map((roundNum: number) => (
                            <TableCell 
                              ref={setRef("round"+roundNum.toString()) as RefObject<Element>}
                              align="center" 
                              key={"roundHeader-"+roundNum.toString()}
                            >
                              <SortIcon column={roundNum}><Typography variant="h3" component="span">{roundNum.toString()}</Typography></SortIcon>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedPlayers.map((player:Player) => (
                          <TableRow key={player.id}>
                            <TableCell className={classes.sticky}>
                              <Grid container wrap="nowrap">
                                <Grid item>
                                  <Box width="200px">
                                    <Typography noWrap variant="h5">
                                      <FontAwesomeIcon icon={faCircle} size="1x" color={parseColor(player.color)} className={classes.playerColorIcon} />
                                      {player.name}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <Box minWidth="50px">
                                    <Typography align="center" variant="h5">{totals[player.id].toString()}</Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </TableCell>
                            {Array.from({length: numRounds}, (value, key) => key+1).map((roundNum: number) => (
                              <TableCell align="center" className={classes.scoreCell} key={"roundPlayer-"+roundNum.toString()+"-"+player.id}>
                                <Typography variant="h5" component="span">{scores[player.id] ? scores[player.id][roundNum] ?? 0 : 0}</Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                : <Box
                    display="flex" 
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box margin="auto">
                      <Typography component="h1" variant="h5">No players have been added to this game.</Typography>
                    </Box>
                  </Box>
              }
            </div>
          : centerLoading()
        }
      </div> 
    }
  }

  return <div className={classes.root}>{result}</div>
}

function centerLoading() {
  return <Box
    display="flex" 
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}
  >
    <Box margin="auto">
      <CircularProgress />
    </Box>
  </Box>
}