import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { AppThunk } from '../../app/store';
import firebaseApp from '../../firebase';

const initialState: Record<string, Record<number, number>> = {};

export const scoresSlice = createSlice({
  name: 'scores',
  initialState,
  reducers: {
    scoresUpdate: (state, action: PayloadAction<{playerId: string, scores: Record<number, number>}>) => {
      state[action.payload.playerId] = action.payload.scores;
    },
  },
});

export const { scoresUpdate } = scoresSlice.actions;

export default scoresSlice.reducer;

let unsubscribes: any[] = [];

export const startPlayerStreams = (gameId: string, playerIds: string[]): AppThunk => {
  return (dispatch: Dispatch<any>) => {
    if (gameId) {
      if (unsubscribes && unsubscribes.length > 0) {
        unsubscribes.forEach((unsubscribe: any) => {
          unsubscribe();
        });
        console.info("Detached all player streams");
      }

      playerIds.forEach((playerId: string) => {
        console.info(`Attaching player score (gameId: ${gameId}, playerId: ${playerId}) stream...`);

        unsubscribes.push(firebaseApp.firestore().collection('games').doc(gameId).collection('scores').doc(playerId).onSnapshot((snapshot) => {
          const snapshotData:firebaseApp.firestore.DocumentData | undefined = snapshot.data();
          const scores: Record<number, number> = {};

          if (snapshot.exists && snapshotData) {
            for (let [roundNum, score] of Object.entries(snapshotData)) {
              scores[Number.parseInt(roundNum)] = score;
            }    
          }

          dispatch(scoresUpdate({
            playerId: playerId,
            scores: scores
          }));
        }, (error) => {
          console.error(error.message)
        }));
        console.info(`Attached player stream (gameId: ${gameId}, playerId: ${playerId})`);
      });
    } else {
      console.info("Not attaching player streams as game id is null or empty");
    }
  }
}