export enum Env { development, test, production }

export const getEnv = (casting: boolean): Env => {
  switch (process.env.NODE_ENV) {
    case 'production':
      // We want to override what env is returned if the URL has "debug" in it as the only
      // way to test the application while casting is to deploy it and therefore setting
      // the NODE_ENV to "production" but we want data from the debug account.
      if (casting && window.location.href.indexOf("debug") !== -1) {
        return Env.development;
      }
      return Env.production;
    case 'test':
      return Env.test;
    case 'development':
      return Env.development;
    default:
      throw new Error('process.env.NODE_ENV returned an unrecognized value');
  }
}

