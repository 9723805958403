import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { castReceiverContext } from '../..';
import { AppThunk, RootState } from '../../app/store';
import firebaseApp, { FIREBASE_RESPONSE } from '../../firebase';
import { gameInit } from '../game/gameSlice';

export enum AuthStatus {
  success = "success",
  error = "error",
  unknown = "unknown"
}

interface AuthState {
  userId: string | undefined,
  status: AuthStatus,
  loading: boolean,
  error: string | undefined,
  restoring: boolean,
  restoringError: boolean | undefined,
};

const initialState: AuthState = { 
  userId: undefined,
  status: AuthStatus.unknown,
  loading: false,
  error: undefined,
  restoring: false,
  restoringError: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    castSignInInit: (state) => {
      state.loading = true;
    },
    castSignInFail: (state, action: PayloadAction<{errorMessage: string}>) => {
      state.loading = false;
      state.error = action.payload.errorMessage;
      state.status = AuthStatus.error;
    },
    googleSignInInit: (state) => {
      state.loading = true;
    },
    googleSignInFail: (state, action: PayloadAction<{errorMessage: string}>) => {
      state.loading = false;
      state.error = action.payload.errorMessage;
      state.status = AuthStatus.error;
    },
    restoreAuthInit: (state) => {
      state.restoring = true;
    },
    restoreAuthSuccess: (state, action: PayloadAction<{userId: string}>) => {
      state.userId = action.payload.userId;
      state.loading = false;
      state.restoring = false;
      state.restoringError = undefined;
      state.status = AuthStatus.success;
    },
    restoreAuthFail: (state) => {
      state.userId = undefined;
      state.loading = false;
      state.restoring = false;
      state.restoringError = true;
      state.status = AuthStatus.error;
    },
    resetAuth: (state) => {
      // https://redux-toolkit.js.org/usage/immer-reducers#resetting-and-replacing-state
      return initialState;
    },
    signOutInit: (state) => {
      return initialState;
    },
    signOutSuccess: (state) => {
      return initialState;
    },
  },
});

export const { castSignInInit, castSignInFail, googleSignInInit, googleSignInFail, resetAuth, restoreAuthInit, restoreAuthSuccess, restoreAuthFail, signOutInit, signOutSuccess } = authSlice.actions;

export const isAuthed = (state: RootState) => {
  return state.auth.status === AuthStatus.success;
};

export default authSlice.reducer;

export const startAuthStream = () => {
  return (dispatch: Dispatch<any>) => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      dispatch(restoreAuthInit());

      if (user !== null) {
        return dispatch(restoreAuthSuccess({userId: user.uid}));
      }

      return dispatch(restoreAuthFail());
    });
  }
}

export const castSignIn = (token: string, gameId: string): AppThunk => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(castSignInInit());
    try {
      await firebaseApp.auth().signInWithCustomToken(token);
      return dispatch(gameInit({ gameId: gameId }));
    } catch (error) {
      castReceiverContext.sendCustomMessage("urn:x-cast:app.tallyr", undefined, {
        'type': 'auth',
        'level': 'error',
        'message': error.message,
      })
      return dispatch(castSignInFail({ errorMessage: error.message }));
    }
  }
}

export const googleSignIn = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(googleSignInInit());
    try {
      await firebaseApp.auth().signInWithPopup(new firebaseApp.auth.GoogleAuthProvider());
    } catch (error) {
      return dispatch(googleSignInFail({ errorMessage: error.message }));
    }

    const currentUser: firebaseApp.User | null = firebaseApp.auth().currentUser;

    if (currentUser && !currentUser.emailVerified) {
      return dispatch(googleSignInFail({ errorMessage: FIREBASE_RESPONSE.USER_DISABLED }));
    }
  }
}

export const signOut = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(signOutInit());
    await firebaseApp.auth().signOut();
    dispatch(signOutSuccess());
  }
}
