import * as React from 'react';
import {
  CircularProgress,
  Typography,
  Grid,
  Container,
  Button
} from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useEffect } from 'react';
import { enqueueSnackbar } from '../notifier/notifierSlice';
import { resetAuth, isAuthed, googleSignIn } from './authSlice';
import { paths } from '../../components/Router';
import { Redirect, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { Env, getEnv } from '../../utils';
import logo from '../../logo-name256.png';

interface LocationState {
  from: {
    pathname: string;
  };
}

const SignIn = () => {
  const dispatch = useDispatch();

  const { error, loading, casting, authed } = useSelector(
    (state: RootState) => ({
      error: state.auth.error,
      loading: state.auth.loading,
      casting: state.casting.casting,
      authed: isAuthed(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (error) {
      dispatch(enqueueSnackbar({
        message: error,
        variant: 'error',
        preventDuplicate: true,
      }));
      dispatch(resetAuth());
    }
  }, [error, dispatch]); 

  let result;
  if (loading) {
    result = <CircularProgress />;
  } else if (error) {
    result = <Typography component="h1" variant="h5" align="center" color="error">Error: {error}</Typography>
  } else if (casting) {
    result = <Typography component="h1" variant="h5" align="center">No Tallyr casting session connected. Open the Tallyr app, navigate to the game you want to cast, and click the cast icon to start a casting session to this device.</Typography>
  } else {
    result = <Button variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faGoogle} />} size="large" onClick={() => dispatch(googleSignIn())}>Sign In With Google</Button> 
  }

  let location = useLocation<LocationState>();
  let { from } = location.state || { from: { pathname: paths.ROOT } };

  return (
    <Container component="main" maxWidth="md">
      <Grid container
        spacing={3}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <img src={logo} alt="Logo" />
        </Grid>
        <Grid item>
          {
            (getEnv(casting) === Env.production && !casting) 
            ? <Typography component="h1" variant="h5">This app only works when using the Tallyr app and casting.</Typography>
            : authed 
              ? <Redirect to={from} />
              : result
          }
        </Grid>
      </Grid>
    </Container>
  );
}

export default SignIn;
