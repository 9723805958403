import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { 
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, 
  persistStore,
  persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import notifierReducer from '../features/notifier/notifierSlice';
import authReducer from '../features/auth/authSlice';
import gameReducer from '../features/game/gameSlice';
import castingReducer from '../features/casting/castingSlice';
import userReducer from '../features/user/userSlice';
import scoresReducer from '../features/scores/scoresSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth']
}

const persistedReducer = persistCombineReducers<any>(persistConfig, {
  auth: authReducer,
  game: gameReducer,
  scores: scoresReducer,
  casting: castingReducer,
  user: userReducer,
  notifier: notifierReducer,
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
